<template>
  <div class="regions">
    <p v-if="directionsCost" class="pogination__text">
      {{ currentPage }}-{{ Math.ceil(rows / perPage) }} из {{ directionsCost.data.length }}
    </p>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      :hide-goto-end-buttons="true"
      :limit="1"
      class="pogination"
    >
      <template #prev-text>
        <img
          class="pagination__btn-icon pagination__btn-icon_prev"
          src="@/assets/png/database-icon-active.png"
          alt=""
        />
      </template>
      <template #next-text>
        <img class="pagination__btn-icon" src="@/assets/png/database-icon-active.png" alt="" />
      </template>
    </b-pagination>
    <div class="table">
      <b-table
        v-if="directionsCost && directionsCost.data"
        id="my-table"
        class="table__box"
        :borderless="true"
        :items="directionsCost.data"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #cell(action)="data">
          <div>
            <a class="table-btn">
              <b-icon
                icon="pencil-fill"
                aria-hidden="true"
                class="mr-2"
                variant="warning"
                @click="$router.push(`/add/new-cost?id=${data.item.id}`)"
              />
            </a>
            <a @click="deleteHandler(data.item.id)">
              <b-icon icon="trash-fill" aria-hidden="true" variant="danger" />
            </a>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "id",
          label: "Номер",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "transport_type",
          label: "Тип авто",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "directions",
          label: "Направления",
          sortable: false,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "distance",
          label: "Расстояние в км по маршруту",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "cost",
          label: "Тариф за 1 км (с НДС)",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "cost_route",
          label: "Стоимость тарифа за 1 рейс",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "action",
          label: "Действия",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-xs",
        },
      ]
    };
  },
  computed: {
    rows() {
      if (this.directionsCost && this.directionsCost.data) {
        this.$emit("spinner");
        return this.directionsCost.data.length;
      }
      return 3;
    },
    ...mapState(["directionsCost"]),
  },
 async created() {
   await this.$store.dispatch("getDirectionsCost");
  },
  methods: {
    deleteHandler(cost_id) {
      let ask = confirm("Are you sure you want to delete?");
      if (ask) {
        this.$api
          .delete("/v2/directions-cost/" + cost_id)
          .then(() => {
            this.$toast.success("Успешно!");
            this.$store.dispatch("getDirectionsCost");
          })
          .catch((e) => {
            this.$toast.error(e.response.data);
          });
      }
    },
  }
};
</script>

<style scoped>
.table-name {
  background: transparent;
}
.pogination,
.pogination__text {
  position: absolute;
  top: -83px;
  right: -3px;
}
.pogination__text {
  right: 128px;
  top: -70px;
}
.page-item:nth-child(2) {
  display: none;
}
.page-item {
  margin-right: 18px;
  border: 1px solid #e0e9fa;
  border-radius: 8px;
}
.sub-header__text {
  margin: 0 20px 0 26px;
  color: #707070;
}
.pagination__btn-icon_prev {
  transform: rotate(180deg);
}
.cities td.input {
  width: 50px;
}
.regions .cities:not(:last-child):after {
  content: ", ";
}
.car-one {
  width: 250px;
}

.table__box {
 width: 100%;
}
</style>
